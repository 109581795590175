import {PickupRequest} from "../Components/PickupResponseAndRequest";
import {Method, useHttp} from "./useHttp";

export const usePickupOrder = (request: PickupRequest | undefined, doRequest: boolean)=>{
    const {isLoading, isError, responseData} = useHttp({
        path: '/pickupOrder' ,
        method: Method.POST,
        stringifiedRequestBody: JSON.stringify(request),
        doRequest: doRequest,
        initialData: {}
    })

    return {isLoading, isError, responseData}
}
