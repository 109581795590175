import {useEffect, useState} from "react"
import {notNullOrEmpty} from "../common/utils"
import {Method, useHttp} from "./useHttp"
import {PickupAgreement} from "../Components/PickupCodeSegment"


export const useAgreementsAPI = (pickupCode: string = '', doRequest: boolean = false) => {
    const [agreements, setAgreements] = useState<PickupAgreement[]>([])

    const {isLoading, isError, responseData, responseStatus} =
        useHttp({
            path: `/pickupCode/${pickupCode}`,
            method: Method.GET,
            initialData: [],
            doRequest: doRequest && notNullOrEmpty(pickupCode)
        })

    useEffect(() => {
        if (notNullOrEmpty(responseData) && !isLoading && !isError) {
            setAgreements(responseData as PickupAgreement[])
        }
        if (isError && !isLoading) {
            setAgreements([])
        }
    }, [responseData, isLoading, isError])

    return {isLoading, isError, agreements, responseStatus}
}
