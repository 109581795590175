import React from 'react'
import PageTitle from './PageTitle'
import PickupCodeSegment from './PickupCodeSegment'
import {I18n} from "../common/i18n";

interface Props {
    isLoading: boolean
    onPickupCodeClick: (value: string)=>void
    pickupCode: string
    pickupCodeError: boolean
}
function Hero({isLoading, onPickupCodeClick, pickupCode, pickupCodeError}:Props) {
    const i18n = I18n.i18n()

    return (
        <div className='hw-hero hw-hero--small'>
            <div className='hw-hero__image'/>
            <div className='hw-hero__overlay hw-background-color-opaque-black'>
                <div className='hw-container' style={{ height: '100%' }}>
                    <div className='hw-hero__content hw-hero__content--middle hw-hero__content--left'>
                        <PageTitle title={i18n.hero.title} />
                        <PickupCodeSegment isError={pickupCodeError} pickupCode={pickupCode} onClick={onPickupCodeClick} isLoading={isLoading}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero
