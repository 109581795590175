import React from 'react'
import PostalCodeLocationInput from "./PostalCodeLocationInput"
import {useFormContext} from 'react-hook-form'
import CountrySelect from "./CountrySelect"
import {I18n} from "../../common/i18n"
import {RequiredAstrisk} from "../RequiredAstrisk";
import {HelpText} from "../HelpText";

interface Props {
    countryCode: string
    onCountryChange: (cc:string)=>void
    countryCodes: string[]
}

function Address({onCountryChange, countryCode, countryCodes}: Props) {
    const i18n = I18n.i18n()
    const t = I18n.t

    const {register, formState: {errors}} = useFormContext()

    return (
        <fieldset className={'hw-fieldset'}>
            <legend className={'hw-fieldset__legend'}>{i18n.pickupWhere}</legend>
            <div className={'hw-fieldset__content'}>
                <label className={`hw-label ${errors.companyName ? 'hw-label--error' : ''}`}>
                    {i18n.companyName}<RequiredAstrisk/>
                    <input
                        className={`hw-input ${errors.companyName ? 'hw-input--error' : ''}`}
                        type='text'
                        {...register("companyName", {
                            required: {
                                value: true,
                                message: i18n.error.required
                            },
                            maxLength: {
                                value: 35,
                                message: t('error.stringRange', {MIN: 1, MAX: 35})
                            }
                        })}
                        autoFocus={true}
                    />
                    {errors.companyName &&
                        <span className='hw-error hw-error--align-left hw-error--indented'>{errors.companyName.message}</span>}
                </label>
                <label className={`hw-label ${errors.address ? 'hw-label--error' : ''}`}>
                    {i18n.address}<RequiredAstrisk/>
                    <input
                        className={`hw-input ${errors.address ? 'hw-input--error' : ''}`}
                        type='text'
                        {...register("address", {
                            required: {
                                value: true,
                                message: i18n.error.required
                            },
                            maxLength: {
                                value: 35,
                                message: t('error.stringRange', {MIN: 1, MAX: 35})
                            }
                        })}
                    />
                    {errors.address &&
                        <span className='hw-error hw-error--align-left hw-error--indented'>{errors.address.message}</span>}
                </label>
                <PostalCodeLocationInput countryCode={countryCode}/>
                <CountrySelect countryCode={countryCode} countryCodes={countryCodes} onChange={onCountryChange}/>
                <label className={`hw-label ${errors.phoneNumber ? 'hw-label--error' : ''}`}>
                    {i18n.phoneNumber}<RequiredAstrisk/><HelpText>{i18n.phoneNumberHelpText}</HelpText>
                    <input
                        className={`hw-input ${errors.phoneNumber ? 'hw-input--error' : ''}`}
                        type='text'
                        {...register("phonenumber", {
                            required: {
                                value: true,
                                message: i18n.error.required
                            },
                            //TODO need to add pattern validation for phonenumber
                        })}
                    />
                    {errors.phonenumber &&
                        <span className='hw-error hw-error--align-left hw-error--indented'>{errors.phonenumber.message}</span>}
                </label>
                <label className={`hw-label ${errors.email ? 'hw-label--error' : ''}`}>
                    {i18n.email}<RequiredAstrisk/><HelpText>{i18n.emailHelpText}</HelpText>
                    <input
                        className={`hw-input ${errors.email ? 'hw-input--error' : ''}`}
                        type='text'
                        {...register("email", {
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: i18n.error.invalidEmail
                            },
                            maxLength: {
                                value: 256,
                                message: t('error.stringRange', {MIN: 5, MAX: 256})
                            }
                        })}
                    />
                    {errors.email &&
                        <span className='hw-error hw-error--align-left hw-error--indented'>{errors.email.message}</span>}
                </label>
                <label className={`hw-label ${errors.contactPerson ? 'hw-label--error' : ''}`}>
                    {i18n.contactPerson}
                    <input
                        className={`hw-input ${errors.contactPerson ? 'hw-input--error' : ''}`}
                        type='text'
                        {...register("contactPerson", {
                            maxLength: {
                                value: 35,
                                message: t('error.stringRange', {MIN: 5, MAX: 35})
                            }
                        })}
                    />
                    {errors.contactPerson && <span
                        className='hw-error hw-error--align-left hw-error--indented'>{errors.contactPerson.message}</span>}
                </label>
                <label className={`hw-label ${errors.pickupInfo ? 'hw-label--error' : ''}`}>
                    {i18n.pickupInfo}<HelpText>{i18n.pickupInfoHelpText}</HelpText>
                    <input
                        className={`hw-input ${errors.pickupInfo ? 'hw-input--error' : ''}`}
                        type='text'
                        {...register("pickupInfo", {
                            maxLength: {
                                value: 35,
                                message: t('error.stringRange', {MIN: 5, MAX: 35})
                            }
                        })}
                    />
                    {errors.pickupInfo &&
                        <span className='hw-error hw-error--align-left hw-error--indented'>{errors.pickupInfo.message}</span>}
                </label>
            </div>
        </fieldset>
    )
}

export default Address
