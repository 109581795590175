// Check if empty/null/undefined
export const isNullOrEmpty = (o: unknown): boolean => {
    if (null === o || typeof o === 'undefined') return true
    if (Object.prototype.hasOwnProperty.call(o, 'length')) return !(o as string).length
    if (Object.prototype.hasOwnProperty.call(o, 'size')) return !(o as Set<unknown>).size
    if (Object.prototype.toString.call(o) == "[object Date]") return false
    if (typeof o === 'number') return false
    for (let k in o as any) {
        if (Object.prototype.hasOwnProperty.call(o, k)) return false
    }

    return true
}

export const notNullOrEmpty = (o: unknown): boolean => !isNullOrEmpty(o)

const leftPad = (s: string): string => ('0' + s).slice(-2)

export const date2YYYYMMDD = (d: Date, delim: string = '-'): string => `${d.getFullYear()}${delim[0]}${('0' + (d.getMonth() + 1).toString()).slice(-2)}${delim[0]}${
        ('0' + d.getDate().toString()).slice(-2)}`

export const date2DDMMYYYY = (d: Date, delim: string = '.'): string => `${('0' + d.getDate().toString()).slice(-2)}${
    delim[0]}${('0' + (d.getMonth() + 1).toString()).slice(-2)}${delim[0]}${d.getFullYear()}`

export const date2HHMM = (d: Date, delim: string = ':'):string => `${leftPad(d.getHours().toString())}${delim[0]}${leftPad(d.getMinutes().toString())}`

export const shallowEqual = (a: unknown, b: unknown): boolean => {
    if (a === b) return true
    if (typeof a != 'object' || typeof b != 'object' || a === null || b === null) return Object.is(a, b)
    return Object.entries(a).sort().toString() === Object.entries(b).sort().toString()
}

export const shallowUnequal = (a: unknown, b: unknown): boolean => !shallowEqual(a, b)
