interface Props { header: string, children: React.ReactNode }

const ErrorBox = ({header, children}: Props) => <div className="error-box-component hw-block hw-block--mt">
    <div className = "hw-error-summary">
        <div className = "hw-error-summary__title">
            <h2>{header}</h2>
        </div>
        <div className={"hw-error-summary__contents"}>{children}</div>
    </div>

</div>

export default ErrorBox
