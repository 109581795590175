import danish from './dan'
import english from './eng'
import {isNullOrEmpty} from '../utils'
// import finnish from './fin'
import norwegian from './nob'
import swedish from './swe'

interface Obj { [y: string]: any }

export const LanguageFiles: {[x: string]: Obj } = {
    dan: danish,
    eng: english,
    // fin: finnish,
    nob: norwegian,
    swe: swedish
}

const tldLanguageMap: Record<string, string> = {
    'com': 'eng',
    'no': 'nob',
    'se': 'swe',
    'dk': 'dan',
}

const iso639_2_1_map: Record<string, string> = {
    'dan': 'da',
    'eng': 'en',
    'fin': 'fi',
    'nob': 'no',
    'swe': 'se',
}

export const languageCodeFromHostname = (hostname: string): string => {
    const def = 'eng'
    const tld = hostname.split('.').pop() || ''
    return (isNullOrEmpty(tld) || isNullOrEmpty(tldLanguageMap[tld])) ? def : tldLanguageMap[tld]
}

const long2shortLanguageCode = (code: string): string => {
    return (iso639_2_1_map[code] || 'en').toUpperCase()
}

export const SupportedLanguages = Object.keys(LanguageFiles)

export const I18n = (() => {
    let language = 'eng'
    let languageFile: Obj = LanguageFiles[language]

    const setLanguage = (languageCode: string = 'eng') => {
        if (!SupportedLanguages.includes(languageCode)) {
            console.warn(`Language code not supported. Got:[${languageCode}], expected:[${SupportedLanguages.join(',')}]`)
            languageCode = 'eng'
        }

        if (!languageFile || languageCode != language) {
            languageFile = LanguageFiles[languageCode]
            language = languageCode
        }
    }

    const getLanguageFile = () => languageFile
    const getLanguageCode = () => language
    const getShortLanguageCode = () => long2shortLanguageCode(language)

    const t = (key: string, replacements?: Record<string, string | number>): string => {
        const keys = key.split('.')
        let out = key
        let values = getLanguageFile() as Obj

        for (let k: string = keys.shift() || ''; !!k; k = keys.shift() || '') {
            const value: Record<string, any> | string = values[k]

            if (!value) break
            if (typeof value == 'object') {
                values = value
            } else {
                out = value.toString()
                break
            }
        }

        for (const k in replacements) {
            out = out.replace(`%{${k}}`, replacements[k].toString()) //Note we use text replacement here, not regex, so global replacements are not possible
        }

        return out
    }

    return {
        t: t,
        i18n: getLanguageFile,
        setLanguage: setLanguage,
        getLanguage: getLanguageCode,
        getShortLanguageCode: getShortLanguageCode
    }
})()
