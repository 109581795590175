import {notNullOrEmpty} from '../common/utils'
import {useEffect, useState} from "react";
import * as Sentry from '@sentry/browser'

export enum Method { 'GET' = 'GET', 'POST' = 'POST'}

export interface HttpResult {
    isLoading: boolean
    isError: boolean
    responseData: unknown
    responseStatus: number
}

interface Props {
    doRequest: boolean
    initialData: unknown
    method: Method
    path: string
    stringifiedRequestBody?: string
}

export function useHttp({path, method, initialData, doRequest, stringifiedRequestBody}: Props): HttpResult {
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [responseData, setResponseData] = useState<unknown>()
    const [responseStatus, setResponseStatus] = useState<number>(0)

    const options: RequestInit = {
        credentials: 'same-origin',
        method: method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    }

    if (method == Method.POST && notNullOrEmpty(stringifiedRequestBody)) {
        options.body = stringifiedRequestBody
    }

    useEffect(() => {
        if (notNullOrEmpty(path) && doRequest) {
            setIsLoading(true)
            let mounted = true
            const abortController = new AbortController()
            options.signal = abortController.signal
            console.debug(`-- Sending request to: ${path}`, options)
            ;(async () => {
                try {
                    const res = await fetch(path, options)
                    setResponseStatus(res.status)
                    const data = await res.json()
                    if (res.ok) {
                        if (mounted) {
                            setResponseData(data)
                            setIsLoading(false)
                            setIsError(false)
                        }
                    } else {
                        if (mounted) {
                            setIsError(true)
                            setIsLoading(false)
                            if (notNullOrEmpty(data)) {
                                setResponseData(data)
                            }
                            Sentry.captureException(new Error(`Request failed (${res.status}): ${path}`))
                        }
                    }
                } catch (error: unknown) {
                    if ((error as Error).name !== 'AbortError') {
                        setIsError(true)
                        setIsLoading(false)
                        console.error('useHttp', error)
                        Sentry.captureException(error)
                    }
                }
            })()
            return () => {
                abortController.abort()
                mounted = false
            }
        } else {
            setResponseData(initialData)
        }
    }, [path, doRequest, stringifiedRequestBody])

    return {
        isLoading,
        isError,
        responseData,
        responseStatus
    }
}

export function useHttpPost(path: string, body: string, initialData: unknown, doRequest: boolean): HttpResult {
    return useHttp({
        doRequest: doRequest,
        initialData: initialData,
        method: Method.POST,
        path: path,
        stringifiedRequestBody: body,
    })
}
