import React, {useEffect, useState} from 'react'
import {useFormContext} from "react-hook-form"
import {I18n} from "../../common/i18n"

interface Props {
    countryCode: string
    countryCodes: string[],
    onChange: (cc:string)=>void
}

const CountrySelect = ({countryCode, countryCodes, onChange}: Props) => {
    const i18n = I18n.i18n()
    const {register} = useFormContext()

    const countryOptions = countryCodes.map((code) => {
        return <option key={code} value={code}>{code}</option>
    })

    return (
        <>
            <label className='hw-label'>
                {i18n.country}
                <select
                    className='hw-dropdown--native'
                    data-hw-dropdown='countries'
                    id='countries'
                    data-testid='countrySelect'
                    {...register('countryCode', {
                        required: false
                    })}
                    defaultValue={countryCode}
                    onChange={(e) => {
                        onChange(e.target.value)
                    }}
                >
                    {countryOptions}
                </select>
            </label>
        </>
    )
}

export default CountrySelect
