import {I18n, SupportedLanguages} from "../common/i18n";

interface Props {
    onChange: (x: string) => void
}

export const LanguageSelector = ({onChange}: Props) => {

    const languageNames: Record<string, string> = {
        eng: 'English',
        dan: 'Dansk',
        nob: 'Bokmål',
        swe: 'Svenska',
    }

    const languageName = (languageCode: string): string => languageNames[languageCode] || languageNames['eng']

    const languageCode = window.localStorage.getItem('bring-rop-language') || I18n.getLanguage()

    return <>
        <label className="sr-only" htmlFor="language-selector">
            {I18n.i18n().nav.selectLanguage}
        </label>
        <select
            id="language-selector"
            name="language-selector"
            className={'mt--small'}
            defaultValue={languageCode}
            onChange={(ev) => onChange(ev.target.value)}
        >
            {SupportedLanguages.map((languageCode) => <option key={languageCode}
                                                              value={languageCode}>{languageName(languageCode)}</option>)}
        </select>
    </>
}
