import React, {useState} from 'react'

interface Props {children: React.ReactNode }

export const HelpText = ({children}:Props) => {
    const [showText, setShowText] = useState(false)
    return (
        <>
            <button
                data-testid="helptext-button"
                type="button"
                className={'help-text--button'}
                onClick={() => setShowText(!showText)}
            >?</button>
            {showText && (
                <div
                    data-testid="helptext-div"
                    className={`help-text--text hw-info-box`}
                >
                    {children}
                </div>
            )}
        </>
    )
}
