export default {
    address: 'Adresse',
    agreementCodePlaceholder: 'ex. P12345678',
    city: 'Sted',
    companyName: 'Navn',
    contactPerson: 'Attentionperson',
    country: 'Land',
    email: 'E-mail for bekræftelse',
    emailHelpText: 'Du kan angive flere e-mailadresser ved at bruge komma mellem adresserne',
    enterAgreementCode: 'Indtast aftalenummer',
    packages: 'Antal pakker',
    palletNO: 'Pall (max 1)',
    pallets: 'Antal paller',
    phoneNumber: 'Telefonnummer',
    phoneNumberHelpText: 'I tilfælde af at chaufføren behøver kontakt i forbindelse med afhentning af sendingerne',
    pickupAddress: 'Afhentningsadresse',
    pickupDate: 'Afhentningsdato',
    pickupDateInfoEarliest: 'Afhenting kan tidligst bestilles %{date}.',
    pickupDateInfoSameDay: 'Ved afhentning samme dag skal bestillingen ske inden kl %{time}.',
    pickupDateInfoTime: 'Afhentningstid for valgt dato (%{date}) er normalt mellom kl %{earliest} og %{latest}.',
    pickupInfo: 'Afhentningsinformation til chauffør',
    pickupInfoHelpText: 'Etagenummer, vejbeskrivelse, etc',
    pickupWhat: 'Hvor skal vi afhente?',
    pickupWhen: 'Hvornår er forsendelsen klar til afhentning?',
    pickupWhere: 'Hvor skal vi afhente?',
    postContainer: 'Postcontainer (max 1)',
    postalCode: 'Postnummer',
    searchButton: 'Søg',
    submitButton: 'Bestill afhenting',
    totalWeight: 'Samlede vægt',
    weightInfoText: 'Max. vægt pr. pakke er 35 kg',
    confirmation: {
        confirmationMail: 'Bekræftelse sendt til',
        labelNotification: 'NB: Alle pakker skal være korrekt mærket når de afhentes.',
        multipleContainer: '%{number} postcontainere',
        multipleItemPickup: '%{number} enheder for afhenting',
        multiplePackage: '%{number} pakker',
        multiplePallet: '%{number} paller',
        packageAndBusinessPalletPickup: 'Package and Business Pallet Pickup',
        packageNumber: 'Ordernummer',
        pickup: 'Afhenting',
        pickupString: '%{formattedDate} mellem %{earliestTime} og %{latestTime}',
        pickupAddress: 'Afhentningsadresse',
        service: 'Service',
        singleContainer: '1 postcontainer',
        singleItemPickup: 'En genstand for afhenting',
        singlePackage: '1 pakke',
        singlePallet: '1 pall',
        swedenWarning: 'Bemærk: i nogle geografiske områder er den ønskede pickup samme dag ikke garanteret, selvom bestillingen er foretaget inden 12:00.',
    },
    error: {
        couldNotReachSgWarning: 'Vi kan ikke bekræfte afhentningsdatoen. Du kan stadig bestille, men vær opmærksom på, at vi muligvis ikke kan hente på netop denne dato.',
        dateMustBeValid: 'Den valgte dato skal være en dato, der er gyldig til hentning',
        failedSubmit: 'Afhentningsordren mislykkedes',
        internalError: 'Teknisk fejl',
        internalErrorInfo: 'Noget gik galt, da vi forsøgte at bekræfte aftalenummeret. Prøv venligst igen, eller kontakt kundeservice.',
        invalidEmail: 'Ugyldig e-mailadresse',
        invalidPostalCode: 'Ugyldig postnummer',
        missingItems: 'Du skal indlæse mindst én pakke eller én palle.',
        notFound: 'Kunne ikke finde afhentningsaftale',
        notFoundInfo: 'Har du indtastet korrekt aftalenummer?',
        notFoundInfo2: 'En gyldig afhentningsaftalekode starter altid med bogstavet P og består af mellem 8 og 12 tegn.',
        notFoundPostalCode: 'Ukendt eller ugyldigt postnummer',
        onlyDigitsAllowed: 'Kun tal er tilladt i dette felt',
        postalCodeRange: 'Postnummer skal bestå af nøjagtigt %{MIN} tall',
        required: 'Dette felt er påkrævet',
        stringRange: 'Indtastede data skal være mellem %{MIN} og %{MAX} tegn',
        tooManyPackages: 'Du kan ikke bestille afhentning for mere end %{MAX} pakker',
        tooManyPallets: 'Du kan ikke bestille afhentning for mere end %{MAX} paller',
        warning: 'Advarsel',
        weightMissingToSmall: 'Vægt skal være et positivt tal',
        weightTooHigh: 'Samlet vægt må ikke overstige %{MAX} kg',
    },
    hero: {
        title: 'Bestil ny afhentning',
    },
    nav: {
        gotoHomePage: 'Gå til hjemmesiden',
        selectLanguage: 'Vælg dit sprog',
    },
}
