import React from 'react'
import {PickupResponse, PickupRequest} from "./PickupResponseAndRequest";
import {I18n} from "../common/i18n";
import {date2DDMMYYYY, date2HHMM} from "../common/utils";

interface Props {
    pickupResponse: PickupResponse | undefined
    pickupRequest: PickupRequest | undefined
}

function Confirmation({pickupResponse, pickupRequest}: Props) {
    const i18n = I18n.i18n()
    const t = I18n.t

    const totalItems = (pickupRequest?.pickupDetails.numberOfPackages || 0)
        + (pickupRequest?.pickupDetails.numberOfPallets || 0)
        + (pickupRequest?.pickupDetails.numberOfPostContainers || 0)

    const grammarMagic = (key: string, number: number): string =>
        t(`confirmation.${number > 1 ? 'multiple' : 'single'}${key}`, {number: number})

    const items = (key: string, number: number) => {
        if (number > 0) {
            return <li>{grammarMagic(key, number)}</li>
        }
        return ''
    }

    const swedenWarning = pickupRequest?.countryCode === 'SE' ?
        <p className='hw-block hw-block--mb'><em>{i18n.confirmation.swedenWarning}</em></p> : ''

    const earliestPickup = new Date(pickupResponse?.pickupConfirmation?.earliestPickupDate || 0)
    const latestPickup = new Date(pickupResponse?.pickupConfirmation?.latestPickupDate || 0)

    return (
        <div className='hw-callout hw-block--mt'>
            <div className='hw-block'>
                <h2 className='hw-h2' data-testid='confirmationHeader'>{i18n.confirmation.title}</h2>
            </div>

            <hr className='hw-hr hw-hr--gray-lighter hw-hr--full hw-block--mb-smaller'/>

            <div className='hw-block'>
                <h3 className='hw-h3' data-testid='serviceTestId'>{i18n.confirmation.service}</h3>
                <p className='hw-block hw-block--mb'>{i18n.confirmation.packageAndBusinessPalletPickup}</p>
            </div>

            <div className='hw-block'>
                <h3 className='hw-h3' data-testid='serviceTestId'>
                    {grammarMagic('ItemPickup', totalItems || 0)}
                </h3>
                <ul>
                    {items('Package', pickupRequest?.pickupDetails.numberOfPackages || 0)}
                    {items('Pallet', pickupRequest?.pickupDetails.numberOfPallets || 0)}
                    {items('Container', pickupRequest?.pickupDetails.numberOfPostContainers || 0)}
                </ul>
            </div>

            <div className='hw-block'>
                <h3 className='hw-h3' data-testid='packageNumberTestId'>{i18n.confirmation.packageNumber}</h3>
                <p className='hw-block hw-block--mb'>{pickupResponse?.pickupConfirmation?.packageNumber}</p>
            </div>

            <div className='hw-block'>
                <h3 className='hw-h3'>{i18n.confirmation.pickup}</h3>
                <p className='hw-block hw-block--mb'>{t('confirmation.pickupString', {
                    formattedDate: date2DDMMYYYY(earliestPickup),
                    earliestTime: date2HHMM(earliestPickup),
                    latestTime: date2HHMM(latestPickup),
                })}</p>
                {swedenWarning}
            </div>

            <div className='hw-block'>
                <h3 className='hw-h3' data-testid='packageNumberTestId'>{i18n.confirmation.pickupAddress}</h3>
                <p className='hw-block hw-block--mb'>{
                    pickupRequest?.pickupAddress.street +
                    ', ' +
                    pickupRequest?.pickupAddress.postalCode
                }
                </p>
            </div>

            <div className='hw-block'>
                <h3 className='hw-h3' data-testid='packageNumberTestId'>{i18n.confirmation.confirmationMail}</h3>
                <p className='hw-block hw-block--mb'>{pickupRequest?.pickupAddress.email}</p>
            </div>


            <div className={'hw-block hw-info-box'}>
                <em>{i18n.confirmation.labelNotification}</em>
            </div>
        </div>
    )
}

export default Confirmation
