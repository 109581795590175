import {isNullOrEmpty, notNullOrEmpty} from "../common/utils";

export interface PickupResponse {
    pickupConfirmation: PickupConfirmation | null,
    errors: Error[] | null
}

export function isValidPickupResponse (res: any): res is PickupResponse {
    return isNullOrEmpty((res as PickupResponse).errors)
}

export function isPickupResponseWithErrors (res: any): res is PickupResponse {
    return notNullOrEmpty((res as PickupResponse).errors)
}

export interface PickupConfirmation {
    status: string,
    earliestPickupDate: number,
    latestPickupDate: number,
    url: string,
    packageNumber: string
}

export interface Error {
    uniqueId: string
    code: string
    messages: Messages[]
    consignmentCorrelationId: string | null
    packageCorrelationId: string | null
}

export interface Messages {
    lang: string
    message: string
}

export interface CustomerInformation {
    customerNumber: string
    companyName: string
}

export interface PickupAddress {
    street: string
    postalCode: string
    email: string
    phoneNumber: string
    contactName: string
    message: string
}

export interface PickupDetails {
    numberOfPackages: number
    numberOfPostContainers: number
    numberOfPallets: number
    weightInGrams: number
}

export interface PickupRequest {
    requestKey: string
    customerInformation: CustomerInformation
    pickupAddress: PickupAddress
    pickupDate: string
    countryCode: string
    service: string
    pickupDetails: PickupDetails
    confirmationEmailLanguage: string
}
