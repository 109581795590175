import React from 'react'

interface Props { title: string }

function PageTitle({title}: Props) {

    return (
        <div className='hw-block hw-block--mb'>
            <h1 className='hw-color-white'>{title}</h1>
        </div>
    )
}

export default PageTitle
