import React from 'react'
import {I18n} from "../common/i18n";
import {LanguageSelector} from "./LanguageSelector";

interface Props {
    onLanguageChange: (value: string) => void
}

function Navbar({onLanguageChange}: Props) {
    const i18n = I18n.i18n()

    return (
        <header className='hw-navbar'>
            <div className='hw-navbar__content'>

                <div className='hw-navbar__top'>
                    <a href='/' className='hw-navbar__logo'>
                        <svg className='hw-navbar__logo-standard' aria-labelledby='Logo' role='img'>
                            <title id='Logo'>{i18n.nav.gotoHomePage}</title>
                            <use xlinkHref={'#logo-bring'}/>
                        </svg>
                        <svg className='hw-navbar__logo-gray' aria-labelledby='Logo' role='img'>
                            <title id='Logo'>{i18n.nav.gotoHomePage}</title>
                            <use xlinkHref={'#logo-bring-gray'}/>
                        </svg>
                    </a>

                    <LanguageSelector onChange={onLanguageChange}/>
                </div>
            </div>
        </header>
    )
}

export default Navbar
