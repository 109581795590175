import React, {useState} from 'react'
import {I18n} from "../common/i18n";

export interface PickupAgreement {
    id: number
    agreementCode: string,
    customerMasterNumber: string,
    product: string,
    fromCountry: string,
    createdAt: string,
    deletedAt: string
}

interface Props {
    isError: boolean
    isLoading: boolean
    onClick: (value: string) => void
    pickupCode: string
}

function PickupCodeSegment({isError, isLoading, pickupCode: initialPickupCode, onClick}: Props) {
    const i18n = I18n.i18n()
    const [pickupCode, setPickupCode] = useState(initialPickupCode)

    const button = (loading: boolean) => <button
            type='submit'
            className={`hw-button hw-button--primary hw-trace__button ${loading ? 'hw-button__spinner' : ''}`}
            data-testid='pickupCodeSearchBtn'
            onClick={() => onClick(pickupCode)}
            disabled={loading}
            >
        <span>{i18n.searchButton}</span>
        </button>


    return (
        <div>
            <form onSubmit={(e) => e.preventDefault()}>
                <label className={`hw-color-white hw-h4`}
                       htmlFor='pickupCodeInput'>{i18n.enterAgreementCode}</label>
                <div
                    className='hw-trace'>
                    <div className='hw-trace__inner'>
                        <input
                            className={`hw-trace__input ${isError && 'hw-input--error'}`}
                            id='pickupCodeInput'
                            type='text'
                            value={pickupCode}
                            onChange={(event) => setPickupCode(event.target.value.trim())}
                            data-testid='pickupCodeInput'
                            placeholder={i18n.agreementCodePlaceholder}
                            autoFocus
                            maxLength={12}
                            minLength={8}
                        />
                    </div>
                    {button(isLoading)}
                </div>
            </form>
        </div>
    )
}

export default PickupCodeSegment
