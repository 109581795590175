import {PickupAgreement} from "../Components/PickupCodeSegment";
import {PickupRequest} from "../Components/PickupResponseAndRequest";


export function makePickupRequest(pickupAgreement: PickupAgreement, data: any, languageCode: string): PickupRequest {

    if (data.countryCode !== 'NO') {
        data.postContainer = '0'
    }

    return {
        requestKey: Math.random().toString(32),
        customerInformation: {
            customerNumber: pickupAgreement.customerMasterNumber,
            companyName: data.companyName
        },
        pickupAddress: {
            street: data.address,
            postalCode: data.postalCode,
            email: data.email,
            phoneNumber: data.phonenumber,
            contactName: data.contactPerson,
            message: data.pickupInfo
        },
        pickupDate: data.pickupDate.toISOString().slice(0,10),
        countryCode: data.countryCode,
        //Need to be changed
        service: "PARCEL",
        pickupDetails: {
            numberOfPackages: parseInt(data.packages || '0', 10),
            numberOfPostContainers: parseInt(data.postContainer || '0', 10),
            numberOfPallets: parseInt(data.pallets || '0', 10),
            weightInGrams: Math.ceil(parseFloat(data.totalWeight?.trim().replace(',', '.') || '0') * 1000),
        },
        confirmationEmailLanguage: languageCode
    }
}

export const getCountryCodesFromAgreement = (pickupAgreement: PickupAgreement[]) => {
    return pickupAgreement.map(agreement => agreement.fromCountry).filter((v, i, a) => a.indexOf(v) === i)
}
