import {useEffect, useState} from "react";
import {Method, useHttp} from "./useHttp";
import {isNullOrEmpty} from "../common/utils";

export interface PostalCode {
    city: string,
    postalcode: string,
    postalCodeType: string
}

interface PostalCodeResponse {
    postalCode?: PostalCode[]
}

export const usePostalCodes = (searchTerm: string, countryCode: string, isValid: boolean) => {
    const [previousSearchTerm, setPreviousSearchTerm] = useState('')
    const [previousCountryCode, setPreviousCountryCode] = useState('')
    const [postalCodesResponse, setPostalCodeResponse] = useState<PostalCodeResponse>()

    const doRequest = isValid && (searchTerm != previousSearchTerm || countryCode != previousCountryCode)

    const {isLoading, isError, responseData} = useHttp({
        path: `/postalCode/?postalCode=${searchTerm}&country=${countryCode}`,
        initialData: [],
        method: Method.GET,
        doRequest: doRequest
    })

    useEffect(()=>{
        if (!isNullOrEmpty(responseData) && !isLoading && !isError) {
            setPreviousCountryCode(countryCode)
            setPreviousSearchTerm(searchTerm)
            setPostalCodeResponse(responseData as PostalCodeResponse)
        }
        if (isError && !isLoading) {
            setPostalCodeResponse({})
        }
    }, [responseData, isLoading, isError])

    return {
        isLoading,
        isError,
        postalCodes: postalCodesResponse?.postalCode || []
    }
}
