import {useEffect, useState} from "react";
import {notNullOrEmpty, shallowEqual} from "../common/utils";
import {useHttpPost} from "./useHttp"

export interface ShippingGuideRequestObject {
    country: string
    postalCode: string
    pickupProduct: string
}

export interface ValidPickupDate {
    codeType?: string
    productCodeInProductionSystem?: string
    earliestPickupISO?: string
    latestPickupISO?: string
}

export const useSG = (body: ShippingGuideRequestObject | null, doRequest: boolean) => {
    const [sgData, setSgData] = useState<ValidPickupDate[]>([])
    const [requestBody, setRequestBody] = useState<ShippingGuideRequestObject | null>(body)
    const [previousRequestBody, setPreviousRequestBody] = useState<ShippingGuideRequestObject | null>(null)

    useEffect(() => {
        if (doRequest && notNullOrEmpty(body) && !shallowEqual(body, previousRequestBody)) {
            setRequestBody(body)
        } else {
            setRequestBody(null)
        }
    }, [body, doRequest])

    const {isLoading, isError, responseData} =
        useHttpPost('/pickup-date', JSON.stringify(requestBody), {},
            doRequest && notNullOrEmpty(requestBody) && !shallowEqual(requestBody, previousRequestBody))

    useEffect(() => {
        if (!isLoading && !isError && notNullOrEmpty(responseData)) {
            setPreviousRequestBody(requestBody as ShippingGuideRequestObject)
            setSgData((responseData as ValidPickupDate[]).map(vpd => ({
                    codeType: vpd.codeType,
                    productCodeInProductionSystem: vpd.productCodeInProductionSystem,
                    earliestPickupISO: vpd.earliestPickupISO?.replace(/\[.+$/, ''),
                    latestPickupISO: vpd.latestPickupISO?.replace(/\[.+$/, ''),
                }))
                .sort((a,b) => ('' + a.earliestPickupISO).localeCompare('' + b.earliestPickupISO))
            )
        }
    }, [responseData, isLoading, isError])

    return {sgData, isLoading, isError}
}

